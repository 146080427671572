import React, { useState, useEffect } from "react";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../firebase";
import WorkerCard from "./cards/WorkerCard";
import styles from "../css/comerciante.module.css";

const SendInvites = ({ job }) => {
  const [workers, setWorkers] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const collectionRef = collection(db, "users");

    const fetchData = async () => {
      try {
        const querySnapshot = await getDocs(collectionRef);
        const documents = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        // Apply filtering with .filter()
        const filteredWorkers = documents.filter(
          (doc) =>
            doc.trade_member === true &&
            doc.tradesSelected.includes(job.tradeSelected) &&
            doc.distritos.includes(job.selectedDistrito)
        );

        setWorkers(filteredWorkers);
      } catch (error) {
        console.error("Error fetching collection:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [job]);

  return (
    <div>
      <br></br>
      <h1 className={styles.titleSend}>
        Trabalhadores <br></br>
        <b>recomendados</b>
      </h1>

      {loading ? (
        <p>Carregando...</p>
      ) : workers.length === 0 ? (
        <p>Nenhum trabalhador foi encontrado para este trabalho.</p>
      ) : (
        workers.map((worker) => (
          <WorkerCard key={worker.id} worker={worker} job={job} />
        ))
      )}
    </div>
  );
};

export default SendInvites;
