import React, { useState, useEffect } from "react";
import { useNavigate, Link, useParams } from "react-router-dom";
import { formatDistanceToNow } from "date-fns";
import { pt } from "date-fns/locale";
import {
  getDoc,
  doc,
  updateDoc,
  collection,
  arrayUnion,
  arrayRemove,
  increment,
} from "firebase/firestore";
import { db, storage } from "../firebase";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { UserAuth } from "../context/AuthContext";
import InterestedUserCard from "../components/cards/InterestedUserCard";
import styles from "../css/jobPage.module.css";
import { servicesData } from "../lib/taxes";

import localIcon from "../imgs/locationIcon.png";
import jobIcon from "../imgs/jobIcon.png";
import userIcon from "../imgs/userIcon.png";
import timeIcon from "../imgs/timeIcon.png";
import addIcon from "../imgs/addIcon.png";

function JobPage(props) {
  const { jobId } = useParams();
  const [job, setJob] = useState(null);
  const { user } = UserAuth();

  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);

  const [usuario, setUsuario] = useState(null);

  const [images, setImages] = useState([]);

  const [confirmationBanner, setConfirmationBanner] = useState(false);

  const handleImageUpload = async (file, index) => {
    const storageRef = ref(
      storage,
      `images/${user.uid}/${job.id}/${file.name}`
    );
    await uploadBytes(storageRef, file);
    const downloadUrl = await getDownloadURL(storageRef);
    setImages((prevImages) => {
      const newImages = [...prevImages];
      newImages[index] = downloadUrl;
      return newImages;
    });
    const imageDocRef = doc(db, "jobs", job.id);
    await updateDoc(imageDocRef, { [`image${index + 1}`]: downloadUrl });
  };

  const handleImageChange = (event, index) => {
    const file = event.target.files[0];
    handleImageUpload(file, index);
  };

  const [interestedUsers, setInterestedUsers] = useState([]);

  const fetchInterestedUsers = async () => {
    if (!job) return;
    const interestedUserIds = job.interestedUsers; // get the interested users' uids from the document
    console.log(job.interestedUsers);

    const interestedUsersInfo = [];

    // loop through each interested user's uid and get their info from the users collection
    for (const userId of interestedUserIds) {
      const userRef = doc(collection(db, "users"), userId);
      const userSnap = await getDoc(userRef);
      const userData = userSnap.data();
      const interestedUserInfo = { id: userId, ...userData };
      interestedUsersInfo.push(interestedUserInfo);
    }

    setInterestedUsers(interestedUsersInfo); // set the interestedUsers state with the fetched data
  };

  const [hiredUser, setHiredUser] = useState(null);

  const fetchJob = async () => {
    const jobDoc = await getDoc(doc(db, "jobs", jobId));

    if (jobDoc.exists()) {
      setJob({ ...jobDoc.data(), id: jobDoc.id });
      setImages([
        jobDoc.data().image1 || "",
        jobDoc.data().image2 || "",
        jobDoc.data().image3 || "",
        jobDoc.data().image4 || "",
        jobDoc.data().image5 || "",
        jobDoc.data().image6 || "",
      ]);

      const userHired = jobDoc.data().userHired;

      if (userHired !== "") {
        // Check if userHired is not empty
        const userHiredDoc = await getDoc(doc(db, "users", userHired));

        if (userHiredDoc.exists()) {
          setHiredUser({ ...userHiredDoc.data(), id: userHiredDoc.id });
        } else {
          console.log("UserHired not found");
        }
      }
    } else {
      console.log("No job found");
    }

    setLoading(false);
  };

  useEffect(() => {
    fetchJob();
  }, [jobId]);

  useEffect(() => {
    fetchUserData();
    fetchInterestedUsers();
  }, [job]);

  const fetchUserData = async () => {
    if (!job) return;
    console.log("userid:" + job.userId);
    const userDoc = await getDoc(doc(db, "users", job.userId));
    const userData = userDoc.data();
    setUsuario(userData);
  };

  const removeImage = (index) => {
    const updatedImages = [...images];

    updatedImages[index] = null;

    setImages(updatedImages);
  };

  function ShowInterest() {
    const jobRef = doc(db, "jobs", job.id);

    updateDoc(jobRef, {
      interestedUsers: arrayUnion(user.uid),
      totalInterestedUsers: increment(1),
    })
      .then(() => {
        console.log('User added to "interested" array');
        const userJobRef = doc(db, "users", user.uid);
        updateDoc(userJobRef, {
          interestedJobs: arrayUnion(job.id),
        })
          .then(() => {
            console.log('Job added to "interested" field in user doc');
          })
          .catch((error) => {
            console.error(
              'Error adding job to "interested" field in user doc:',
              error
            );
          });
      })
      .catch((error) => {
        console.error('Error adding user to "interested" array:', error);
      });

    setConfirmationBanner(false);
  }

  function RemoveInterest() {
    const jobRef = doc(db, "jobs", job.id);
    updateDoc(jobRef, {
      interestedUsers: arrayRemove(user.uid),
      totalInterestedUsers: increment(-1),
    });

    const userRef = doc(db, "users", user.uid);
    updateDoc(userRef, {
      interestedJobs: arrayRemove(job.id),
    });
  }

  if (loading) {
    return <div>Carregando...</div>;
  }
  return (
    <div className={styles.postTrabalho}>
      {confirmationBanner ? (
        <div className={styles.confirmationbanner}>
          <div className={styles.confirmationcard}>
            <img src={jobIcon} width={35} height={35} />
            <br></br>
            <br></br>

            {user.credits &&
            user.credits >
              servicesData[job.tradeSelected][job.selectedCategory] ? (
              `Caso seja adicionado como possível trabalhador, serão debitados ${
                servicesData[job.tradeSelected][job.selectedCategory]
              } créditos da sua conta`
            ) : (
              <>
                <p>
                  Créditos insuficientes. Precisa de{" "}
                  {servicesData[job.tradeSelected][job.selectedCategory]}{" "}
                  créditos
                </p>
                <p>Pode adquirir mais em Minha Conta {">"} Créditos</p>
              </>
            )}
            <div className={styles.confirmationbuttons}>
              <button
                className={styles.confirmationno}
                onClick={() => setConfirmationBanner(false)}
              >
                {user.credits &&
                user.credits >
                  servicesData[job.tradeSelected][job.selectedCategory]
                  ? "Cancelar"
                  : "Ok"}
              </button>
              {user.credits &&
                user.credits >
                  servicesData[job.tradeSelected][job.selectedCategory] && (
                  <button
                    className={styles.confirmationyes}
                    onClick={() => ShowInterest()}
                  >
                    Continuar
                  </button>
                )}
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}

      <div className={styles.postTrabalhoGeral}>
        <div
          style={{
            width:
              user.uid == job.userId && window.innerWidth > 1680
                ? "67.5%"
                : "100%",
          }}
          className={styles.postTrabalhoEsq}
        >
          <div className={styles.infoTrabalho}>
            <table border="1" cellpadding="10">
              <thead>
                <tr>
                  <th>
                    <img src={localIcon} width="15" height="15" alt="MEUJOB" />
                    Localização
                  </th>
                  <th>
                    <img src={jobIcon} width="15" height="15" alt="MEUJOB" />
                    Trabalho
                  </th>
                  <th>
                    <img src={userIcon} width="15" height="15" alt="MEUJOB" />
                    Publicado por
                  </th>
                  <th>
                    <img src={timeIcon} width="15" height="15" alt="MEUJOB" />
                    Publicado
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Lisboa</td>
                  <td>{job.tradeSelected}</td>
                  <td>{usuario ? usuario.firstName : ""}</td>
                  <td>
                    {formatDistanceToNow(
                      new Date(job.createdAt.seconds * 1000),
                      {
                        addSuffix: true,
                        locale: pt,
                      }
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className={styles.infoTrabalhoMobile}>
            <table border="1" cellpadding="10">
              <tbody>
                <tr>
                  <th>
                    <img src={localIcon} width="15" height="15" alt="MEUJOB" />
                    Localização
                  </th>
                  <td>Lisboa</td>
                </tr>
                <tr>
                  <th>
                    <img src={jobIcon} width="15" height="15" alt="MEUJOB" />
                    Trabalho
                  </th>
                  <td>{job.tradeSelected}</td>
                </tr>
                <tr>
                  <th>
                    <img src={userIcon} width="15" height="15" alt="MEUJOB" />
                    Publicado por
                  </th>
                  <td>{usuario ? usuario.firstName : ""}</td>
                </tr>
                <tr>
                  <th>
                    <img src={timeIcon} width="15" height="15" alt="MEUJOB" />
                    Publicado
                  </th>
                  <td>
                    {formatDistanceToNow(
                      new Date(job.createdAt.seconds * 1000),
                      {
                        addSuffix: true,
                        locale: pt,
                      }
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          {job.userHired == "" && (
            <div className={styles.interessados}>
              {user.uid == job.userId ? (
                <div
                  style={{
                    width: "100%",
                    marginTop: "20px",
                  }}
                >
                  <p
                    style={{
                      fontFamily: "DM Sans",
                      color: "#508ce4",
                      letterSpacing: -0.8,
                      textAlign: "center",
                    }}
                  >
                    {job.shortlistedUsers.length} pré-selecionados de{" "}
                    {job.totalInterestedUsers} interessados
                  </p>
                  <div
                    style={{
                      backgroundColor: "#ddd",
                      boxShadow: "inset 0 0 10px rgba(0, 0, 0, 0.05)",
                      borderRadius: "100px",
                      position: "relative",
                      height: "35px",
                      width: "100%",
                    }}
                  >
                    <div
                      style={{
                        backgroundColor: "#508ce4",
                        width: `${
                          job.totalInterestedUsers > 0
                            ? (job.shortlistedUsers.length /
                                job.totalInterestedUsers) *
                              100
                            : 0
                        }%`,
                        height: "100%",
                        borderRadius: "100px",
                        transition: "width 0.3s ease",
                      }}
                    ></div>
                    <span
                      style={{
                        position: "absolute",
                        top: "0",
                        left: "50%",
                        transform: "translateX(-50%)",
                        color: "#fff",
                        fontWeight: "bold",
                        fontFamily: "DM Sans",
                        letterSpacing: -0.5,
                        lineHeight: "35px",
                      }}
                    >
                      {job.totalInterestedUsers > 0
                        ? `${Math.round(
                            (job.shortlistedUsers.length /
                              job.totalInterestedUsers) *
                              100
                          )}%`
                        : "0%"}
                    </span>
                  </div>
                </div>
              ) : (
                <>
                  {user.interestedJobs.includes(job.id) &&
                  !user.shortlistedJobs.includes(job.id) ? (
                    <div className={styles.botoes}>
                      <button
                        className={styles.removeInterestBtn}
                        onClick={() => RemoveInterest()}
                      >
                        Remover interesse
                      </button>
                    </div>
                  ) : (
                    <>
                      {user.shortlistedJobs.includes(job.id) ? (
                        <>
                          {usuario && (
                            <>
                              <br></br>
                              <p className={styles.infoNome}>
                                Foste adicionado a <b>lista restrita.</b> Liga
                                para fechar o negócio!
                              </p>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  marginBottom: -15,
                                  marginTop: -20,
                                }}
                              >
                                <img
                                  style={{
                                    width: 25,
                                    height: 25,
                                    marginRight: 10,
                                  }}
                                  src={require("../imgs/phoneVitalie.png")}
                                />
                                <p
                                  className={styles.infoNome}
                                  style={{
                                    color: "#508ce4",
                                    fontSize: 25,
                                    fontWeight: "bold",
                                  }}
                                >
                                  {usuario.phone}
                                </p>
                              </div>
                            </>
                          )}
                        </>
                      ) : (
                        <div className={styles.botoes}>
                          <button
                            className={styles.showInterestBtn}
                            onClick={() => setConfirmationBanner(true)}
                          >
                            <img
                              src={addIcon}
                              width={20}
                              height={20}
                              alt="MEUJOB"
                              style={{
                                filter: "invert(1)",
                                marginRight: 10,
                                marginBottom: -2,
                              }}
                            />
                            Mostrar Interesse
                          </button>
                        </div>
                      )}
                    </>
                  )}
                </>
              )}
            </div>
          )}

          {job.userId != user.uid &&
            (job.userHired != user.uid && job.userHired != "" ? (
              <div>
                <h1>
                  Que penaaa..., outra pessoa foi escolhida para fazer o
                  trabalho. Vais conseguir da próxima vez
                </h1>
              </div>
            ) : (
              <div>
                <h1>Foste Contratado, Parabéns</h1>
              </div>
            ))}

          <div className={styles.descricoesTrabalhoMain}>
            <div className={styles.descricoesTrabalho}>
              <div className={styles.descricoesTrabalhoZones}>
                <img src={jobIcon} width={25} height={25} alt="MEUJOB" />
                <div className={styles.descricoesTrabalhoZonesFilho}>
                  <h3>Descrição do trabalho</h3>
                  <p>
                    <b>{job.selectedSubCategory}</b>
                    <br></br>
                    {job.selectedCategory}
                  </p>
                </div>
              </div>
            </div>

            <div className={styles.descricoesTrabalho}>
              <div className={styles.descricoesTrabalhoZones}>
                <img src={userIcon} width={25} height={25} alt="MEUJOB" />
                <div className={styles.descricoesTrabalhoZonesFilho}>
                  <h3>Descrição do cliente</h3>
                  <p>{job.description}</p>
                </div>
              </div>
            </div>
          </div>

          <div className={styles.descricoesTrabalhoImagens}>
            <h3 style={{ marginTop: 0 }}>Imagens</h3>
            <div className={styles.postImagens}>
              <div className={styles.postImagesScroll}>
                {images.map((imageUrl, index) => (
                  <div key={index}>
                    {user.uid === job.userId && (
                      <button
                        onClick={() => {
                          document.getElementById(`input-${index}`).click();
                        }}
                        style={{ display: imageUrl ? "none" : "block" }}
                      >
                        +
                      </button>
                    )}
                    <input
                      id={`input-${index}`}
                      type="file"
                      onChange={(event) => handleImageChange(event, index)}
                      style={{ display: "none" }}
                    />
                    {imageUrl && (
                      <div className={styles.imageContainer}>
                        <img
                          src={imageUrl}
                          alt=""
                          onClick={() => removeImage(index)}
                        />
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>

        {user.uid == job.userId && (
          <div className={styles.criticasConvites}>
            {user.uid == job.userId ? (
              <div className={styles.criticas}>
                <>
                  {job.userHired == "" ? (
                    <div>
                      <h3>Trabalhadores interessados</h3>
                      {interestedUsers.length > 0 ? (
                        <>
                          {interestedUsers.map((user) => (
                            <Link
                              to={`/meustrabalhos/${jobId}/trabalhador/${user.id}`}
                              state={{ user, job }}
                            >
                              <InterestedUserCard key={user.id} value={user} />
                            </Link>
                          ))}
                        </>
                      ) : (
                        <div>
                          <p>
                            Os trabalhadores interessados no seu trabalho
                            aparecerão aqui.
                          </p>
                          <h5 style={{ marginBottom: 0, marginTop: 15 }}>
                            Esperando mais trabalhadores...
                          </h5>
                        </div>
                      )}
                    </div>
                  ) : (
                    <div>
                      <h3>Trabalhador contratado</h3>
                      <Link
                        style={{
                          textAlign: "center",
                          textDecoration: "none",
                          color: "#508ce4",
                          fontFamily: "DM Sans",
                          letterSpacing: -0.8,
                          fontSize: 18,
                        }}
                        to={`/meustrabalhos/${jobId}/trabalhador/${hiredUser.id}`}
                      >
                        <div
                          style={{
                            backgroundColor: "#eee",
                            boxShadow: "inset 0 0 10px rgba(0, 0, 0, 0.05)",
                            borderRadius: "100px",
                            position: "relative",
                            width: "100%",
                            paddingTop: 1,
                            paddingBottom: 1,
                          }}
                        >
                          <h4>{hiredUser.workName}</h4>
                          <p
                            style={{
                              fontSize: 14,
                              marginTop: -25,
                              paddingBottom: 5,
                              fontFamily: "DM Sans",
                            }}
                          >
                            {hiredUser.reviewCount} crítica(s) 👍🏻{" "}
                            {hiredUser.reviewCount !== 0 ? (
                              <>
                                {(hiredUser.positiveReviewCount /
                                  hiredUser.reviewCount) *
                                  100}{" "}
                                %
                              </>
                            ) : (
                              "Ainda sem críticas"
                            )}
                          </p>
                        </div>
                      </Link>
                    </div>
                  )}
                </>
              </div>
            ) : null}
            <br></br>
            {job.userHired == "" && job.userId == user.uid && (
              <div className={styles.convites}>
                <div className={styles.convitesTitle}>
                  <h3>Convites</h3>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <h5>{job.invitesLeft} restantes</h5>
                  </div>
                </div>
                <p style={{ marginTop: -5 }}>
                  Notificamos trabalhadores relevantes para o seu trabalho. Pode
                  ter respostas mais rápidas se convidar trabalhadores!
                </p>
                <Link
                  to={`/convidar-trabalhadores/${jobId}`}
                  className={styles.btnConvite}
                >
                  Convida trabalhadores
                </Link>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default JobPage;
